body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
} 

@font-face {
  font-family: "NotoSansHans";
  src: local("NotoSansHans-Regular"),
    /* local("NotoSansHans-Regular"), */ 
    url('./assets/NotoSanaSChinese/NotoSansHans-Regular.otf');
  /* font-weight: bold; */
  /* font-style: normal; */
  /* font-variant: small-caps; 
  font-stretch: expanded;  
  unicode-range: U+0025-00FF; */
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import 'antd/dist/antd.css';


.text3 { display: -webkit-box; text-overflow: ellipsis; overflow: hidden; -webkit-line-clamp: 3; word-break: break-word; -webkit-box-orient: vertical; }
.text2 { display: -webkit-box; text-overflow: ellipsis; overflow: hidden; -webkit-line-clamp: 2; word-break: break-word; -webkit-box-orient: vertical; }